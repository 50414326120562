.select2-container--bootstrap5 .select2-selection {
  box-shadow: none !important;
  height: auto;
  outline: none !important;
}
.select2-container--bootstrap5.select2-container--focus .form-select-solid, .select2-container--bootstrap5.select2-container--open .form-select-solid {
  background-color: #eef3f7;
}
.select2-container--bootstrap5.select2-container--focus .form-select:not(.form-select-solid):not(.form-select-transparent), .select2-container--bootstrap5.select2-container--open .form-select:not(.form-select-solid):not(.form-select-transparent) {
  border-color: #B5B5C3;
}
.select2-container--bootstrap5 .select2-search.select2-search--inline .select2-search__field {
  color: #181C32;
  font-weight: 500;
  background-color: transparent;
  border: 0;
  box-shadow: none;
  outline: none;
  margin: 0;
  padding: 0;
}
.select2-container--bootstrap5 .select2-search.select2-search--inline .select2-search__field::placeholder {
  color: #A1A5B7;
}
.select2-container--bootstrap5 .select2-search.select2-search--inline .select2-search__field::-moz-placeholder {
  color: #A1A5B7;
  opacity: 1;
}
.select2-container--bootstrap5 .form-select-solid .select2-search.select2-search--inline .select2-search__field {
  color: #5E6278;
}
.select2-container--bootstrap5 .form-select-solid .select2-search.select2-search--inline .select2-search__field::placeholder {
  color: #A1A5B7;
}
.select2-container--bootstrap5 .form-select-solid .select2-search.select2-search--inline .select2-search__field::-moz-placeholder {
  color: #A1A5B7;
  opacity: 1;
}
.select2-container--bootstrap5 .select2-selection--single {
  display: flex;
  align-items: center;
}
.select2-container--bootstrap5 .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #A1A5B7;
}
.select2-container--bootstrap5 .select2-selection--single .select2-selection__placeholder {
  color: #A1A5B7;
}
.select2-container--bootstrap5 .select2-selection--single.form-select-solid .select2-selection__rendered {
  color: #5E6278;
}
.select2-container--bootstrap5 .select2-selection--single.form-select-solid .select2-selection__placeholder {
  color: #A1A5B7;
}
.select2-container--bootstrap5 .select2-selection--single.form-select-transparent .select2-selection__rendered {
  color: #3F4254;
}
.select2-container--bootstrap5 .select2-selection--single.form-select-transparent .select2-selection__placeholder {
  color: #3F4254;
}
.select2-container--bootstrap5 .select2-selection--multiple .select2-search.select2-search--inline {
  display: inline;
}
.select2-container--bootstrap5 .select2-selection--multiple .select2-selection__rendered {
  display: inline;
  margin: 0;
  padding: 0;
}
.select2-container--bootstrap5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
  display: inline-flex;
  align-items: center;
  position: relative;
  background-color: #E4E6EF;
}
.select2-container--bootstrap5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
  display: block;
  position: absolute;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%235E6278'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  opacity: 0.5;
  border: 0;
  transition: color 0.2s ease, background-color 0.2s ease;
  top: 50%;
}
.select2-container--bootstrap5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove span {
  display: none;
}
.select2-container--bootstrap5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove:hover {
  opacity: 1;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23008bcb'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  transition: color 0.2s ease, background-color 0.2s ease;
}
.select2-container--bootstrap5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__display {
  font-weight: 500;
}
.select2-container--bootstrap5 .select2-selection--multiple:not(.form-select-sm):not(.form-select-lg) {
  padding-top: calc((1.5rem + 2px - 0.8rem) / 2);
  padding-bottom: calc((1.5rem + 2px - 0.8rem) / 2);
}
.select2-container--bootstrap5 .select2-selection--multiple:not(.form-select-sm):not(.form-select-lg) .select2-selection__choice {
  border-radius: 0.4rem;
  padding: 0.2rem 0.4rem;
  margin: 0.2rem 0.4rem 0.2rem 0;
}
.select2-container--bootstrap5 .select2-selection--multiple:not(.form-select-sm):not(.form-select-lg) .select2-selection__choice .select2-selection__choice__remove {
  height: 0.6rem;
  width: 0.6rem;
  margin-right: 0.5rem;
}
.select2-container--bootstrap5 .select2-selection--multiple:not(.form-select-sm):not(.form-select-lg) .select2-selection__choice .select2-selection__choice__display {
  margin-left: 1.2rem;
  font-size: 0.9rem;
}
.select2-container--bootstrap5 .select2-selection--multiple:not(.form-select-sm):not(.form-select-lg) .select2-search.select2-search--inline .select2-search__field {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: calc(0.75rem - (1.5rem + 2px - 0.8rem) / 2);
  margin-bottom: calc(0.75rem - (1.5rem + 2px - 0.8rem) / 2);
}
.select2-container--bootstrap5 .select2-selection--multiple.form-select-lg {
  padding-top: calc((1.65rem + 2px - 0.8rem) / 2);
  padding-bottom: calc((1.65rem + 2px - 0.8rem) / 2);
}
.select2-container--bootstrap5 .select2-selection--multiple.form-select-lg .select2-selection__choice {
  border-radius: 0.4rem;
  padding: 0.2rem 0.4rem;
  margin: 0.2rem 0.4rem 0.2rem 0;
}
.select2-container--bootstrap5 .select2-selection--multiple.form-select-lg .select2-selection__choice .select2-selection__choice__remove {
  height: 0.6rem;
  width: 0.6rem;
  margin-right: 0.5rem;
}
.select2-container--bootstrap5 .select2-selection--multiple.form-select-lg .select2-selection__choice .select2-selection__choice__display {
  margin-left: 1.2rem;
  font-size: 1rem;
}
.select2-container--bootstrap5 .select2-selection--multiple.form-select-lg .select2-search.select2-search--inline .select2-search__field {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: calc(0.825rem - (1.65rem + 2px - 0.8rem) / 2);
  margin-bottom: calc(0.825rem - (1.65rem + 2px - 0.8rem) / 2);
}
.select2-container--bootstrap5 .select2-selection--multiple.form-select-sm {
  padding-top: calc((1.1rem + 2px - 0.6rem) / 2);
  padding-bottom: calc((1.1rem + 2px - 0.6rem) / 2);
}
.select2-container--bootstrap5 .select2-selection--multiple.form-select-sm .select2-selection__choice {
  border-radius: 0.4rem;
  padding: 0.15rem 0.4rem;
  margin: 0.15rem 0.4rem 0.15rem 0;
}
.select2-container--bootstrap5 .select2-selection--multiple.form-select-sm .select2-selection__choice .select2-selection__choice__remove {
  height: 0.5rem;
  width: 0.5rem;
  margin-right: 0.5rem;
}
.select2-container--bootstrap5 .select2-selection--multiple.form-select-sm .select2-selection__choice .select2-selection__choice__display {
  margin-left: 1rem;
  font-size: 0.8rem;
}
.select2-container--bootstrap5 .select2-selection--multiple.form-select-sm .select2-search.select2-search--inline .select2-search__field {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: calc(0.55rem - (1.1rem + 2px - 0.6rem) / 2);
  margin-bottom: calc(0.55rem - (1.1rem + 2px - 0.6rem) / 2);
}
.select2-container--bootstrap5 .select2-dropdown {
  border: 0;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  border-radius: 0.475rem;
  padding: 1rem 0;
  background-color: #ffffff;
}
.modal-open .select2-container--bootstrap5 .select2-dropdown {
  z-index: 1056;
}
.select2-container--bootstrap5 .select2-dropdown .select2-search {
  padding: 0.5rem 1.25rem;
  margin: 0 0 0.5rem 0;
}
.select2-container--bootstrap5 .select2-dropdown .select2-search .select2-search__field {
  background-color: #ffffff;
  padding: 0.55rem 0.75rem;
  color: #181C32;
  font-size: 0.925rem;
  border: 1px solid #E4E6EF;
  border-radius: 0.475rem;
  outline: 0 !important;
}
.select2-container--bootstrap5 .select2-dropdown .select2-search .select2-search__field:focus, .select2-container--bootstrap5 .select2-dropdown .select2-search .select2-search__field:active {
  border-color: 1px solid #181C32;
}
.select2-container--bootstrap5 .select2-dropdown .select2-results__options {
  max-height: 250px;
  overflow-y: auto;
}
.select2-container--bootstrap5 .select2-dropdown .select2-results__option {
  color: #5E6278;
  transition: color 0.2s ease, background-color 0.2s ease;
  padding: 0.75rem 1.25rem;
  margin: 0 0;
}
.select2-container--bootstrap5 .select2-dropdown .select2-results__option.select2-results__option--highlighted {
  background-color: transparent;
  color: #5E6278;
}
.select2-container--bootstrap5 .select2-dropdown .select2-results__option:not(.select2-results__option--group):hover {
  background-color: #E8FFF3;
  color: #008bcb;
  transition: color 0.2s ease, background-color 0.2s ease;
}
.select2-container--bootstrap5 .select2-dropdown .select2-results__option.select2-results__option--selected {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 11'%3e%3cpath fill='%23FFFFFF' d='M4.89557 6.49823L2.79487 4.26513C2.26967 3.70683 1.38251 3.70683 0.857309 4.26513C0.375593 4.77721 0.375593 5.57574 0.857309 6.08781L4.74989 10.2257C5.14476 10.6455 5.81176 10.6455 6.20663 10.2257L13.1427 2.85252C13.6244 2.34044 13.6244 1.54191 13.1427 1.02984C12.6175 0.471537 11.7303 0.471536 11.2051 1.02984L6.06096 6.49823C5.74506 6.83403 5.21146 6.83403 4.89557 6.49823Z'/%3e%3c/svg%3e");
  background-size: 0.8rem;
  background-position: center right 1.25rem;
  background-color: #008bcb;
  color: #FFFFFF;
  transition: color 0.2s ease, background-color 0.2s ease;
}
.select2-container--bootstrap5 .select2-dropdown .select2-results__option.select2-results__option--disabled {
  color: #B5B5C3;
}
.select2-container--bootstrap5 .select2-dropdown .select2-results__option.select2-results__message {
  color: #7E8299;
}
.select2-container--bootstrap5 .select2-dropdown .select2-results__option.select2-results__option--group {
  padding-left: 0;
  padding-right: 0;
}
.select2-container--bootstrap5 .select2-dropdown .select2-results__option.select2-results__option--group .select2-results__group {
  display: block;
  color: #3F4254;
  font-weight: 500;
  font-size: 1.15rem;
  padding: 0 1.25rem 0 1.25rem;
  margin: 0 0 0.25rem 0;
}
.select2-container--bootstrap5 .select2-dropdown .select2-results__option.select2-results__option--group .select2-results__option {
  padding: 0.75rem 1.25rem;
  margin: 0 0;
}
.select2-container--bootstrap5 .select2-selection__clear {
  display: block;
  height: 0.7rem;
  width: 0.7rem;
  top: 50%;
  right: 3rem;
  position: absolute;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%235E6278'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
}
.select2-container--bootstrap5 .select2-selection__clear span {
  display: none;
}
.select2-container--bootstrap5 .select2-selection__clear:hover {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23008bcb'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}

.fv-plugins-message-container {
  margin-top: 0.3rem;
}
.fv-plugins-message-container .fv-help-block {
  color: #f14141;
  font-size: 1rem;
  font-weight: 400;
}
.fv-plugins-message-container.valid-feedback, .fv-plugins-message-container.invalid-feedback {
  display: block;
  font-weight: 400;
}

.daterangepicker {
  padding: 0;
  margin: 0;
  border: 0;
  width: auto;
  background-color: #ffffff;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  font-family: "Nunito Sans", Helvetica, Arial, Lucida, sans-serif;
  z-index: 1000;
  border-radius: 0.475rem;
}
.daterangepicker:after, .daterangepicker:before {
  display: none;
}
.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
  background-color: transparent;
}
.modal-open .daterangepicker {
  z-index: 1056;
}
.daterangepicker .calendar-table {
  background-color: #ffffff;
  border: 0;
}
.daterangepicker .ranges {
  border-radius: 0.475rem;
  background-color: #ffffff;
}
.daterangepicker .ranges ul {
  padding: 1rem 0;
  width: 175px;
}
.daterangepicker .ranges li {
  padding: 0.7rem 1.75rem;
  font-weight: 500;
  font-size: 1rem;
  color: #7E8299;
  transition: color 0.2s ease, background-color 0.2s ease;
}
.daterangepicker .ranges li:hover {
  background-color: #E8FFF3;
  color: #008bcb;
  transition: color 0.2s ease, background-color 0.2s ease;
}
.daterangepicker .ranges li.active {
  background-color: #008bcb;
  color: #FFFFFF;
  transition: color 0.2s ease, background-color 0.2s ease;
}
.daterangepicker.show-calendar .ranges {
  border-radius: 0;
  border-top-left-radius: 0.475rem;
  margin-top: 0;
  height: 297px;
}
.daterangepicker.show-ranges.show-calendar .ranges {
  border-right: 1px solid #EFF2F5;
}
.daterangepicker.show-ranges .drp-calendar.left {
  border-left: 0;
}
.daterangepicker .drp-buttons {
  padding: 1rem 1.75rem;
  border-top: 1px solid #EFF2F5;
}
.daterangepicker .drp-buttons .btn {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
}
.daterangepicker .drp-buttons .cancelBtn {
  color: #7E8299;
  border-color: #F5F8FA;
  background-color: #F5F8FA;
}
.daterangepicker .drp-buttons .cancelBtn i {
  color: #7E8299;
}
.daterangepicker .drp-buttons .cancelBtn .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7E8299;
}
.daterangepicker .drp-buttons .cancelBtn .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.daterangepicker .drp-buttons .cancelBtn.dropdown-toggle:after {
  color: #7E8299;
}
.btn-check:checked + .daterangepicker .drp-buttons .cancelBtn, .btn-check:active + .daterangepicker .drp-buttons .cancelBtn, .daterangepicker .drp-buttons .cancelBtn:focus:not(.btn-active), .daterangepicker .drp-buttons .cancelBtn:hover:not(.btn-active), .daterangepicker .drp-buttons .cancelBtn:active:not(.btn-active), .daterangepicker .drp-buttons .cancelBtn.active, .daterangepicker .drp-buttons .cancelBtn.show, .show > .daterangepicker .drp-buttons .cancelBtn {
  color: #7E8299;
  border-color: #EFF2F5;
  background-color: #EFF2F5 !important;
}
.btn-check:checked + .daterangepicker .drp-buttons .cancelBtn i, .btn-check:active + .daterangepicker .drp-buttons .cancelBtn i, .daterangepicker .drp-buttons .cancelBtn:focus:not(.btn-active) i, .daterangepicker .drp-buttons .cancelBtn:hover:not(.btn-active) i, .daterangepicker .drp-buttons .cancelBtn:active:not(.btn-active) i, .daterangepicker .drp-buttons .cancelBtn.active i, .daterangepicker .drp-buttons .cancelBtn.show i, .show > .daterangepicker .drp-buttons .cancelBtn i {
  color: #7E8299;
}
.btn-check:checked + .daterangepicker .drp-buttons .cancelBtn .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .daterangepicker .drp-buttons .cancelBtn .svg-icon svg [fill]:not(.permanent):not(g), .daterangepicker .drp-buttons .cancelBtn:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .daterangepicker .drp-buttons .cancelBtn:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .daterangepicker .drp-buttons .cancelBtn:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .daterangepicker .drp-buttons .cancelBtn.active .svg-icon svg [fill]:not(.permanent):not(g), .daterangepicker .drp-buttons .cancelBtn.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .daterangepicker .drp-buttons .cancelBtn .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7E8299;
}
.btn-check:checked + .daterangepicker .drp-buttons .cancelBtn .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .daterangepicker .drp-buttons .cancelBtn .svg-icon svg:hover [fill]:not(.permanent):not(g), .daterangepicker .drp-buttons .cancelBtn:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .daterangepicker .drp-buttons .cancelBtn:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .daterangepicker .drp-buttons .cancelBtn:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .daterangepicker .drp-buttons .cancelBtn.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .daterangepicker .drp-buttons .cancelBtn.show .svg-icon svg:hover [fill]:not(.permanent):not(g), .show > .daterangepicker .drp-buttons .cancelBtn .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .daterangepicker .drp-buttons .cancelBtn.dropdown-toggle:after, .btn-check:active + .daterangepicker .drp-buttons .cancelBtn.dropdown-toggle:after, .daterangepicker .drp-buttons .cancelBtn:focus:not(.btn-active).dropdown-toggle:after, .daterangepicker .drp-buttons .cancelBtn:hover:not(.btn-active).dropdown-toggle:after, .daterangepicker .drp-buttons .cancelBtn:active:not(.btn-active).dropdown-toggle:after, .daterangepicker .drp-buttons .cancelBtn.active.dropdown-toggle:after, .daterangepicker .drp-buttons .cancelBtn.show.dropdown-toggle:after, .show > .daterangepicker .drp-buttons .cancelBtn.dropdown-toggle:after {
  color: #7E8299;
}
.daterangepicker .drp-selected {
  font-size: 0.9rem;
}
.daterangepicker .drp-calendar.left, .daterangepicker .drp-calendar.right {
  padding: 1rem 1rem;
}
.daterangepicker .drp-calendar.left {
  border-left: 0 !important;
}
.daterangepicker .drp-calendar th,
.daterangepicker .drp-calendar td {
  font-size: 1rem;
  font-weight: 400;
  width: 33px;
  height: 33px;
}
.daterangepicker .drp-calendar th.available:hover,
.daterangepicker .drp-calendar td.available:hover {
  border-radius: 0.475rem;
  background-color: #E8FFF3;
  color: #008bcb;
}
.daterangepicker .drp-calendar th {
  font-weight: 500;
  color: #3F4254;
}
.daterangepicker .drp-calendar th.month {
  font-weight: 500;
  color: #3F4254;
}
.daterangepicker .drp-calendar th.next span, .daterangepicker .drp-calendar th.prev span {
  border-width: 0 1px 1px 0;
  border-color: #7E8299;
}
.daterangepicker .drp-calendar th.next.available:hover span, .daterangepicker .drp-calendar th.prev.available:hover span {
  border-color: #008bcb;
}
.daterangepicker .drp-calendar th.next span {
  margin-right: 1px;
}
.daterangepicker .drp-calendar th.prev span {
  margin-left: 1px;
}
.daterangepicker .drp-calendar td {
  color: #5E6278;
}
.daterangepicker .drp-calendar td.available.off {
  color: #B5B5C3;
}
.daterangepicker .drp-calendar td.active {
  background-color: #008bcb !important;
  color: #FFFFFF !important;
  border-radius: 0.475rem;
}
.daterangepicker .drp-calendar td.active.start-date {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.daterangepicker .drp-calendar td.active.end-date {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.daterangepicker .drp-calendar td.active.start-date.end-date {
  border-radius: 0.475rem;
}
.daterangepicker .drp-calendar td.today, .daterangepicker .drp-calendar td.today.active {
  background: #E8FFF3 !important;
  color: #008bcb !important;
  border-radius: 0.475rem;
}
.daterangepicker .drp-calendar td.in-range.available:not(.active):not(.off):not(.today) {
  background-color: #E8FFF3;
  color: #008bcb;
}
.daterangepicker .drp-calendar td:hover {
  background-color: #E8FFF3;
  color: #008bcb;
}
.daterangepicker select.ampmselect,
.daterangepicker select.minuteselect,
.daterangepicker select.hourselect,
.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  border-radius: 0.475rem;
  background: transparent;
  border-color: transparent;
  color: #181C32;
  font-weight: 500;
  outline: 0 !important;
}
.daterangepicker select.ampmselect:focus,
.daterangepicker select.minuteselect:focus,
.daterangepicker select.hourselect:focus,
.daterangepicker select.monthselect:focus,
.daterangepicker select.yearselect:focus {
  background: #F5F8FA;
}

@media (max-width: 767.98px) {
  .daterangepicker.show-calendar .ranges {
    float: none !important;
    height: auto !important;
  }
  .daterangepicker.show-calendar .ranges ul {
    width: 100%;
  }
  .daterangepicker.show-calendar .drp-calendar {
    float: none !important;
    max-width: unset !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.flatpickr-calendar {
  width: 280px !important;
  font-family: inherit;
  border: 0;
  border-radius: 0;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  background-color: #ffffff;
  border-radius: 0.475rem;
}
.flatpickr-calendar:before, .flatpickr-calendar:after {
  display: none;
}
.flatpickr-calendar.hasWeeks {
  width: 325px !important;
}

.flatpickr-months {
  padding: 0 1rem;
  padding-top: 0.5rem;
}

.flatpickr-innerContainer {
  padding: 0.5rem 1rem;
}

.flatpickr-days,
.dayContainer {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
}

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: #7E8299;
  fill: #7E8299;
  height: 46px;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.475rem;
  top: 1rem;
}
.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  fill: #A1A5B7;
  height: 13px;
  width: 13px;
}
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  background: #F5F8FA;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #5E6278;
}
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*rtl:begin:ignore*/
  left: 1rem;
  /*rtl:end:ignore*/
}
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*rtl:begin:ignore*/
  right: 1rem;
  /*rtl:end:ignore*/
}

.flatpickr-current-month {
  font-weight: 500;
  color: inherit;
}
.flatpickr-current-month .numInputWrapper {
  border-radius: 0.475rem;
  width: 65px;
}
.flatpickr-current-month .numInputWrapper span.arrowUp {
  border-top-right-radius: 0.475rem;
}
.flatpickr-current-month .numInputWrapper span.arrowDown {
  border-bottom-right-radius: 0.475rem;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  border: 0 !important;
  background-color: #ffffff;
  font-size: 1rem;
  color: #5E6278;
  font-weight: 500;
  padding: 0.5rem 0.75rem;
  margin-right: 0.5rem;
  outline: none !important;
  border-radius: 0.475rem;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: #F5F8FA;
}
.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  font-size: 1rem;
  color: #5E6278;
  font-weight: 500;
  background-color: transparent;
  outline: none;
  padding: 0;
}
.flatpickr-current-month span.cur-month {
  color: #5E6278;
  font-size: 1rem;
  font-weight: 500;
}
.flatpickr-current-month span.cur-month:hover {
  background: #F5F8FA;
}
.flatpickr-current-month input.cur-year {
  color: #5E6278;
  font-size: 1.1rem;
  padding: 0.5rem 0.75rem;
  font-weight: 500;
  outline: 0 !important;
}

span.flatpickr-weekday {
  color: #3F4254;
  font-size: 1rem;
  font-weight: 600;
}

.flatpickr-time {
  border-bottom-right-radius: 0.475rem;
  border-bottom-left-radius: 0.475rem;
}
.flatpickr-calendar.hasTime .flatpickr-time {
  height: height;
  line-height: height;
  max-height: height;
  border-top: 1px solid #F5F8FA;
}
.flatpickr-time .numInputWrapper {
  height: height;
}
.flatpickr-time .flatpickr-am-pm {
  color: #5E6278;
  font-size: 1rem;
  font-weight: 500;
}
.flatpickr-time input.flatpickr-hour, .flatpickr-time input.flatpickr-minute {
  color: #5E6278;
  font-size: 1rem;
  font-weight: 500;
}
.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: transparent;
}

.numInputWrapper span {
  border-left: 0 !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
  border-right: 0 !important;
}
.numInputWrapper span:hover {
  background: transparent !important;
}
.numInputWrapper span:after {
  top: 50% !important;
  transform: translateY(-50%);
}
.numInputWrapper span.arrowUp:after {
  border-bottom-color: #A1A5B7 !important;
}
.numInputWrapper span.arrowUp:hover:after {
  border-bottom-color: #5E6278 !important;
}
.numInputWrapper span.arrowDown:after {
  border-top-color: #A1A5B7 !important;
}
.numInputWrapper span.arrowDown:hover:after {
  border-top-color: #5E6278 !important;
}
.numInputWrapper:hover {
  background: transparent;
}

.flatpickr-day {
  font-size: 1rem;
  border-radius: 0.475rem;
  box-shadow: none !important;
  height: 36px;
  width: 100%;
  max-width: 100% !important;
  margin: 0;
  line-height: 36px;
  color: #7E8299;
  margin-top: 0 !important;
}
.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #E8FFF3;
  color: #008bcb;
  border-color: transparent;
}
.flatpickr-day.today {
  background: #F5F8FA;
  color: #7E8299;
  border-color: transparent;
}
.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  border-color: transparent;
  background: #EFF2F5;
  color: #5E6278;
}
.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: #008bcb;
  color: #FFFFFF;
  border-color: transparent;
}
.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #E8FFF3;
  color: #008bcb;
  border-color: transparent;
}
.flatpickr-day.today {
  border-color: transparent;
}
.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  border-color: transparent;
  background: #F5F8FA;
  color: #7E8299;
}
.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: #008bcb;
  color: #FFFFFF;
  border-color: transparent;
}
.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: #B5B5C3;
  background: transparent;
  border-color: transparent;
}
.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: #B5B5C3;
}

.flatpickr-weekwrapper {
  margin-right: 5px;
}

.bootstrap-maxlength {
  z-index: 1040 !important;
}
.bootstrap-maxlength.badge {
  display: inline-flex !important;
}
.modal .bootstrap-maxlength {
  z-index: 1060 !important;
}

.dropzone {
  min-height: auto;
  padding: 1.5rem 1.75rem;
  text-align: center;
  cursor: pointer;
  border: 1px dashed #008bcb;
  background-color: #E8FFF3;
  border-radius: 0.475rem !important;
}
.dropzone .dz-message {
  margin: 0;
  display: flex;
  text-align: left;
}
.dropzone .dz-preview {
  border-radius: 0.475rem !important;
  margin: 0.75rem;
}
.dropzone .dz-preview .dz-image {
  border-radius: 0.475rem !important;
  z-index: 1;
}
.dropzone .dz-preview.dz-file-preview .dz-image {
  background: #EFF2F5;
}
.dropzone .dz-success-mark,
.dropzone .dz-error-mark {
  margin-left: -20px !important;
  margin-top: -20px !important;
}
.dropzone .dz-success-mark svg,
.dropzone .dz-error-mark svg {
  height: 40px !important;
  width: 40px !important;
}
.dropzone .dz-remove {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.65rem;
  width: 1.65rem;
  font-size: 1rem;
  text-indent: -9999px;
  white-space: nowrap;
  position: absolute;
  z-index: 2;
  background-size: 40%;
  background-color: #ffffff !important;
  box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05);
  border-radius: 100%;
  top: -0.825rem;
  right: -0.825rem;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23A1A5B7'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}
.dropzone .dz-remove:hover {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23008bcb'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}
.dropzone .dz-error-message {
  color: #FFFFFF;
  background: #f14141;
}

.dropzone.dropzone-queue {
  border: 0;
  padding: 0;
  background-color: transparent;
  text-align: left;
}
.dropzone.dropzone-queue .dz-message {
  display: none;
}
.dropzone.dropzone-queue .dropzone-panel .dropzone-upload,
.dropzone.dropzone-queue .dropzone-panel .dropzone-remove-all {
  display: none;
}
.dropzone.dropzone-queue .dropzone-item {
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
  border-radius: 0.475rem;
  padding: 0.5rem 1rem;
  background-color: #F5F8FA;
}
.dropzone.dropzone-queue .dropzone-item .dropzone-file {
  flex-grow: 1;
}
.dropzone.dropzone-queue .dropzone-item .dropzone-file .dropzone-filename {
  font-size: 0.9rem;
  font-weight: 500;
  color: #7E8299;
  text-overflow: ellipsis;
  margin-right: 0.5rem;
}
.dropzone.dropzone-queue .dropzone-item .dropzone-file .dropzone-filename:hover {
  color: #008bcb;
}
.dropzone.dropzone-queue .dropzone-item .dropzone-file .dropzone-error {
  margin-top: 0.25rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: #f14141;
  text-overflow: ellipsis;
}
.dropzone.dropzone-queue .dropzone-item .dropzone-progress {
  width: 15%;
}
.dropzone.dropzone-queue .dropzone-item .dropzone-progress .progress {
  height: 5px;
  transition: all 0.2s ease;
}
@media (prefers-reduced-motion: reduce) {
  .dropzone.dropzone-queue .dropzone-item .dropzone-progress .progress {
    transition: none;
  }
}
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar {
  margin-left: 1rem;
  display: flex;
  flex-wrap: nowrap;
}
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-start,
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-cancel,
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-delete {
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color 0.2s ease, background-color 0.2s ease;
}
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-start i,
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-cancel i,
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-delete i {
  transition: color 0.2s ease, background-color 0.2s ease;
  font-size: 0.8rem;
  color: #7E8299;
}
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-start:hover,
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-cancel:hover,
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-delete:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-start:hover i,
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-cancel:hover i,
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-delete:hover i {
  color: #008bcb;
}
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-start {
  transition: color 0.2s ease, background-color 0.2s ease;
}

html.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown),
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown):not(.modal-open) {
  overflow-y: initial !important;
}
html.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown):not(.sweetalert2-nopadding),
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown):not(.modal-open):not(.sweetalert2-nopadding) {
  padding-right: 0 !important;
}

.swal2-popup {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 0.475rem;
}
.swal2-popup .swal2-title {
  font-weight: 500;
  font-size: 1.3rem;
  color: #181C32;
}
.swal2-popup .swal2-html-container,
.swal2-popup .swal2-content {
  font-weight: normal;
  font-size: 1.1rem;
  margin-top: 1.5rem;
  color: #3F4254;
}
.swal2-popup .btn {
  margin: 15px 5px 0;
}
.swal2-popup .swal2-styled:focus {
  box-shadow: none;
}
.swal2-popup .swal2-actions {
  margin: 1.5rem auto 1rem auto;
}

.swal2-container {
  overflow-y: hidden !important;
}
.swal2-container.swal2-shown {
  background-color: rgba(0, 0, 0, 0.2);
}
.swal2-container .swal2-html-container {
  max-height: 200px;
  overflow: auto;
}

body.swal2-height-auto {
  height: 100% !important;
}

.swal2-icon.swal2-warning {
  border-color: #FFC700;
  color: #FFC700;
}
.swal2-icon.swal2-error {
  border-color: #f14141;
  color: #f14141;
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  background-color: rgba(241, 65, 65, 0.75);
}
.swal2-icon.swal2-success {
  border-color: #34ba00;
  color: #34ba00;
}
.swal2-icon.swal2-success [class^=swal2-success-line] {
  background-color: #34ba00;
}
.swal2-icon.swal2-success .swal2-success-ring {
  border-color: rgba(52, 186, 0, 0.3);
}
.swal2-icon.swal2-info {
  border-color: #39d5ea;
  color: #39d5ea;
}
.swal2-icon.swal2-question {
  border-color: #008bcb;
  color: #008bcb;
}

.tox-target {
  display: none;
}

.tox-tinymce {
  border-radius: 0.475rem !important;
  height: 500px !important;
}

.toastr {
  transition: all 0.2s ease;
  background-position: 1.5rem center /*rtl:calc(100% - 1.5rem) center*/ !important;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15) !important;
  border-radius: 0.475rem !important;
  border: 0 !important;
  background-color: #F5F8FA;
  color: #5E6278;
  opacity: 0.85 !important;
  padding: 1.25rem 1.25rem 1.25rem 4.5rem !important;
}
.toastr:hover {
  transition: all 0.2s ease;
  opacity: 1 !important;
}
.toastr .toastr-close-button {
  outline: none !important;
  font-size: 0;
  width: 0.85rem;
  height: 0.85rem;
}
.toastr .toastr-title {
  font-size: 1.15rem;
  font-weight: 500;
}
.toastr .toastr-title + .toastr-message {
  margin-top: 0.25rem;
}
.toastr .toastr-message {
  font-size: 1rem;
  font-weight: 400;
}
.toastr.toastr-success {
  background-color: #34ba00;
  color: #FFFFFF;
}
.toastr.toastr-success .toastr-close-button {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}
.toastr.toastr-info {
  background-color: #39d5ea;
  color: #FFFFFF;
}
.toastr.toastr-info .toastr-close-button {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}
.toastr.toastr-warning {
  background-color: #FFC700;
  color: #FFFFFF;
}
.toastr.toastr-warning .toastr-close-button {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}
.toastr.toastr-error {
  background-color: #f14141;
  color: #FFFFFF;
}
.toastr.toastr-error .toastr-close-button {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}

.toastr-top-center {
  top: 12px;
}

.toastr-bottom-center {
  bottom: 12px;
}