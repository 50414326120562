//
// Content
//

// Desktop mode
@include media-breakpoint-up(lg) {
	.content {
		padding: 0 0 get($content-spacing, tablet-and-mobile) 0;

		// Aside enabled
		.aside-enabled & {			
			padding-left: get($content-spacing, desktop);
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	.content {
		padding: get($content-spacing, tablet-and-mobile) 0 0 0;
	}
}
