//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Primary
$primary:       									if(isDarkMode(), #008bcb, #008bcb);
$primary-active:    								if(isDarkMode(), #36508e, #36508e);
$primary-light:    									if(isDarkMode(), #1C3238, #E8FFF3);
$primary-inverse:  									#FFFFFF;

// Success
$success:       									if(isDarkMode(), #4ec94a, #34ba00);
$success-active:    								if(isDarkMode(), #28cf30, #00bd1f);
$success-light:    								    if(isDarkMode(), #1c5723, #f1fff4);
$success-inverse:  									#FFFFFF;

// Info
$info:       									    if(isDarkMode(), #50cbfc, #39d5ea);
$info-active:    									if(isDarkMode(), #37d9ee, #14bad0);
$info-light:    									if(isDarkMode(), #2F264F, #F8F5FF);
$info-inverse:  									#FFFFFF;

// Danger
$danger:       									    if(isDarkMode(), #f64e4e, #f14141);
$danger-active:    									if(isDarkMode(), #ee2d2d, #d92121);
$danger-light:    									if(isDarkMode(), #3A2434, #FFF5F8);
$danger-inverse:  									#FFFFFF;

// Warning
$warning:       									if(isDarkMode(), #FFA800, #FFC700);
$warning-active:    								if(isDarkMode(), #EE9D01, #F1BC00);
$warning-light:    									if(isDarkMode(), #392F28, #FFF8DD);
$warning-inverse:  									#FFFFFF;

// Card
$card-bg:                                           if(isDarkMode(), #1E1E2D, rgba(245, 248, 250, 0.35));
$card-box-shadow: 									none; 
$card-border-enabled: 								true;